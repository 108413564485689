import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
      color: 'green !important',
      textTransform: 'none',
  },
  cancelBtn: {
      color: '#F32013 !important',
      textTransform: 'none',
  },
  navBtn: {
      textTransform: 'none',
  },
});

export default function HorizontalNonLinearStepper(props) {

  const classes = useStyles();
    //   const [activeStep, setActiveStep] = React.useState(0);
    // //   const [completed, setCompleted] = React.useState<{
    // //     [k: number]: boolean;
    // //   }>({});

    //   const totalSteps = () => {
    //     return props.steps.length;
    //   };

    //   const completedSteps = () => {
    //     return Object.keys(completed).length;
    //   };

    //   const isLastStep = () => {
    //     return activeStep === totalSteps() - 1;
    //   };

    //   const allStepsCompleted = () => {
    //     return completedSteps() === totalSteps();
    //   };

    //   const handleNext = () => {
    //     const newActiveStep =
    //       isLastStep() && !allStepsCompleted()
    //         ? // It's the last step, but not all props.steps have been completed,
    //           // find the first step that has been completed
    //           props.steps.findIndex((step, i) => !(i in completed))
    //         : activeStep + 1;
    //     setActiveStep(newActiveStep);
    //   };

    //   const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    //   };



    //   const handleComplete = () => {
    //     const newCompleted = completed;
    //     newCompleted[activeStep] = true;
    //     setCompleted(newCompleted);
    //     handleNext();
    //   };

    //   const handleReset = () => {
    //     setActiveStep(0);
    //     setCompleted({});
    //   };

    const handleStep = (step: number) => () => {
        props.handleStep(step);
    };

    let completed = props.steps.map(step => step.completed);

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={props.activeStep - 1}>
                {props.steps.map((step, index) => (
                    <Step key={step.name} completed={completed[index]}>
                        <StepButton classes={classes} onClick={handleStep(index)}>
                            {step.name}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            {/* <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All props.steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              {activeStep !== props.steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Finish'
                      : 'Complete Step'}
                  </Button>
                ))}
            </Box>
          </React.Fragment>
        )}
      </div> */}
        </Box>
    );
}
