//login actions
export const LOGINCLICKED = 'LOGINCLICKED';
export const EMAIL_VERIFICATION_ERR = 'EMAIL_VERIFICATION_ERR';
export const PSSWRD_VERIFICATION_ERR = 'PSSWRD_VERIFICATION_ERR';
export const API_ERR = 'API_ERR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

//breadcrumb actions
export const TABLOADED = 'TABLOADED';
export const CHANGEPATH = 'CHANGEPATH';

//StoresCategories actions
export const GET_STORESCATEGORIES = 'GET_STORESCATEGORIES';
export const GET_STORESCATEGORIES_SUCCESS = 'GET_STORESCATEGORIES_SUCCESS';
export const GET_STORESCATEGORIES_FAILURE = 'GET_STORESCATEGORIES_FAILURE';
export const SAVE_STORESCATEGORIES_LOCALLY = 'SAVE_STORESCATEGORIES_LOCALLY';

//create store actions
export const STOREINFONEXT = 'STOREINFONEXT';
//itemscategories
export const ITEMSCATEGORIESNEXT = 'ITEMSCATEGORIESNEXT';
export const ITEMSCATEGORIESPREVIOUS = 'ITEMSCATEGORIESPREVIOUS';
export const ADDITEMCATEGORY = 'ADDITEMCATEGORY';
export const REMOVEITEMCATEGORY = 'REMOVEITEMCATEGORY';
//items
export const ITEMSNEXT = 'ITEMSNEXT';
export const ITEMSPREVIOUS = 'ITEMSPREVIOUS';
export const ADDITEM = 'ADDITEM';
export const REMOVEITEM = 'REMOVEITEM';
//options
export const OPTIONSNEXT = 'OPTIONSNEXT';
export const OPTIONSPREVIOUS = 'OPTIONSPREVIOUS';
export const ADDOPTION = 'ADDOPTION';
export const ADDOPTIONVALUE = 'ADDOPTIONVALUE';
export const REMOVEOPTION = 'REMOVEOPTION';
export const REMOVEOPTIONVALUE = 'REMOVEOPTIONVALUE';
export const ADDITEMFOROPTIONS = 'ADDITEMFOROPTIONS';
export const REMOVEITEMFROMOPTIONS = 'REMOVEITEMFROMOPTIONS';
//Extras
export const EXTRASNEXT = 'EXTRASNEXT';
export const EXTRASPREVIOUS = 'EXTRASPREVIOUS';
export const ADDITEMFOREXTRAS = 'ADDITEMFOREXTRAS';
export const REMOVEITEMFROMExtras = 'REMOVEITEMFROMExtras';
export const ADDEXTRA = 'ADDEXTRA';
export const REMOVEEXTRA = 'REMOVEEXTRA';
//PricingVariants
export const SAVEVARIANTSINSTORE = 'SAVEVARIANTSINSTORE';

export const CHANGEINPUT = 'CHANGEINPUT';
//stores
export const UPLOADSTORE = 'UPLOADSTORE';
export const GETSTORES = 'GETSTORES';
export const SAVE_STORES_LOCALLY = 'SAVE_STORES_LOCALLY';
export const DELETESTORE = 'DELETESTORE';
export const DELETEINGSTORE = 'DELETEINGSTORE';
export const STOREDELETEDSUCCESSFULLY = 'STOREDELETEDSUCCESSFULLY';
export const DELETESTOREFAILED = 'DELETESTOREFAILED';
export const UPDATESTORE_GET = 'UPDATESTORE_GET';
export const UPDATEINGSTORE_GET = 'UPDATEINGSTORE_GET';
export const UPDATEINGSTORE_GET_SUCCESS = 'UPDATEINGSTORE_GET_SUCCESS';
export const UPDATEINGSTORE_GET_FAILURE = 'UPDATEINGSTORE_GET_FAILURE';
export const EDITINPUT = 'EDITINPUT';
export const UPDATESTORE_POST = 'UPDATESTORE_POST';
export const UPDATINGSTORE_POST = 'UPDATINGSTORE_POST';
export const UPDATINGSTORE_POST_SUCCESS = 'UPDATINGSTORE_POST_SUCCESS';
export const UPDATINGSTORE_POST_FAILURE = 'UPDATINGSTORE_POST_FAILURE';
export const STORES_WILLUNMOUNT = 'STORES_WILLUNMOUNT';
export const RESETUPDATESTORE_SUCCESS = 'RESETUPDATESTORE_SUCCESS';
export const RESETUPDATESTORE_FAILURE = 'RESETUPDATESTORE_FAILURE';
export const RESET_STOREDELETEDSUCCESSFULLY = 'RESET_STOREDELETEDSUCCESSFULLY';
export const RESET_DELETESTOREFAILURE = 'RESET_DELETESTOREFAILURE';
//items categories actions
export const GET_ITEMSCATEGORIES = 'GET_ITEMSCATEGORIES';
export const SAVE_ITEMSCATEGORIES_LOCALLY = 'SAVE_ITEMSCATEGORIES_LOCALLY';
export const ADDSTOREITEMCATEGORY = 'ADDSTOREITEMCATEGORY';
export const REMOVESTOREITEMCATEGORY = 'REMOVESTOREITEMCATEGORY';
export const ITEMSCATEGORIES_ADDSTORE = 'ITEMSCATEGORIES_ADDSTORE';
export const ITEMSCATEGORIES_REMOVESTORE = 'ITEMSCATEGORIES_REMOVESTORE';
export const ITEMSCATEGORIES_CHANGEINPUT = 'ITEMSCATEGORIES_CHANGEINPUT';
export const GETSTORES_ITEMSCATGORIES = 'GETSTORES_ITEMSCATGORIES';
export const SAVESTORES_ITEMSCATEGORIES = 'SAVESTORES_ITEMSCATEGORIES';
export const UPLOADITEMSCATEGORIES = 'UPLOADITEMSCATEGORIES';
export const ITEMSCATEGORIESUPLOADEDSUCCESSFULLY = 'ITEMSCATEGORIESUPLOADEDSUCCESSFULLY';
export const ITEMSCATEGORIESUPLOADEFAILED = 'ITEMSCATEGORIESUPLOADEFAILED';
export const RESET_IC_S = 'RESET_IC_S';
export const RESET_IC_F = 'RESET_IC_F';
//orders
export const ORDERSDIDMOUNT = 'ORDERSDIDMOUNT';
export const GETNEWORDERS = 'GETNEWORDERS';
export const GETLASTORDERID = 'GETLASTORDERID';
export const SAVELASTORDERID = 'SAVELASTORDERID';
export const SAVENEWORDERS = 'SAVENEWORDERS';
export const SAVEORDERSLOCALLY = 'SAVEORDERSLOCALLY';
export const CLOSENOTIFICATIONLISTOPEN = 'CLOSENOTIFICATIONLISTOPEN';
export const OPENNOTIFICATIONLISTOPEN = 'OPENNOTIFICATIONLISTOPEN';
export const SETOPENEDORDERID = 'SETOPENEDORDERID';
export const ORDERREADY = 'ORDERREADY';
export const SENDORDERREADYREQUEST = 'SENDORDERREADYREQUEST';
export const ORDERREADYFAILED = 'ORDERREADYFAILED';


export const LOAD_CONFIG = 'LOAD_CONFIG';